@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .swiper-slide {
            height: auto;
        }

        .swiperArrowWrapper {
            right: 0;
            top: 0;

            button {
                background: transparent;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .card {

            .post-title {
                line-height: 1.4;
                font-weight: 500;
                @include trans;
            }

            .post-location,
            .post-sector,
            .post-type,
            .post-salary {
                font-weight: 300;
                font-size: 0.875rem;
            }

            &:hover,
            &:focus,
            &:active {
                color: $font-color;

                .post-title {
                    color: $primary-color;
                }
            }
        }

        .post-sector {
            span {
                &:not(:last-child) {
                    margin-right: 0.5rem;

                    &:after {
                        content: ","
                    }
                }
            }
        }

        .js-container {
            width: 100%;

            .js-form-input-wrapper {
                background: $base-white;
                @include flex;
                @include border-radius(50px);
                padding: 0.5rem 0.5rem 0.5rem 2rem;
                border: 1px solid $base-white;

                .js-input-wrapper-query {
                    padding-right: 1rem;
                    width: 100%;
                    @include flex;
                    @include align-items(center);

                    input {
                        outline: none;
                        width: 100%;
                        background: transparent;
                        color: $font-color;
                        border: none;
                    }
                }

                .js-button {
                    white-space: nowrap;
                    @include primaryBtn;
                    margin: 0;
                    border: none;

                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}